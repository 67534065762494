@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.3 !default;

$spacing-unit:     20px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1280px !default;

// Fonts
@font-face {
  font-family: "Jost";
  src: url("/assets/fonts/Jost-300-Light.ttf") format('truetype');
}
@font-face {
  font-family: "Jost Book";
  src: url("/assets/fonts/Jost-400-Book.ttf") format('truetype');
}

@mixin on-mobile() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin not-on-mobile() {
  @media not (hover: none), not (pointer: coarse) {
    @content;
  }
}

@mixin on-small-screen() {
  @media screen and (max-width: 800px) {
    @content;
  }
}

@mixin on-big-screen() {
  @media screen and (min-width: 801px) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

.photo-gallery {
	list-style: none;
	padding: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.photo-gallery li {
  float: left;
  margin: 5px;
  overflow: hidden;
  font-size: small;
}

@include not-on-mobile() {
  .img-container {
    background: #eee none 0 0 no-repeat;
    width: 220px;
    height: 220px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
}

.photo-gallery img {
	max-height: 180px;
  max-width: 180px;
  border: 1px solid #555;
  @include on-mobile() {
    border: none;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
}

.photo-gallery div:hover {
  background-color: #bbb
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
  padding-right: 15px;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
