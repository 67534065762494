/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  background-color: $grey-color-light;
  opacity: 0.8;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  @include on-small-screen() {
    @include relative-font-size(1.2);
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    @include relative-font-size(1.25);

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include on-mobile() {
    position: absolute;
    top: 9px;
    right: calc($spacing-unit / 2);
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: calc($spacing-unit / 4) 0;
  background-color: $grey-color-light;
  opacity: 0.8;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color-dark;
  margin-left: calc(-1 * $spacing-unit / 2);
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc($spacing-unit / 2);
  padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(30% - (#{$spacing-unit} / 2));
  width:         calc(30% - (#{$spacing-unit} / 2));
}

@include on-small-screen() {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.5);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $base-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.25);
}

.post-home {
  @include on-small-screen() {
    display: none
  }
}

.galleries-home {
  width: 65%;
  @include on-small-screen() {
    width: 100%;
  }
}

.gallery-link {
  @include relative-font-size(1.25);
}

.gallery-link-current {
  text-decoration: underline;
  color: $text-color;
  text-decoration-thickness: 2px;
  text-decoration-style: dotted;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include on-small-screen() {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include on-small-screen() {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include on-small-screen() {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include on-small-screen() {
      @include relative-font-size(1.125);
    }
  }
}

/**
* Background
*/
.crossfade > div {
  animation: imageAnimation 28s linear infinite;
  backface-visibility: hidden;
  @include on-big-screen() {
    background-size: cover;
  }
  @include on-small-screen() {
    background-size: cover;
  }
  background-position: center center;
  background-repeat: no-repeat;


  color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.crossfade {
  height: 0px;
}
@keyframes imageAnimation {
  0% { opacity:1; }
  17% { opacity:1; }
  25% { opacity:0; }
  92% { opacity:0; }
  100% { opacity:1; }
}
@include on-big-screen(){
  .crossfade div:nth-of-type(1) {
    background-image: url("/images/bg/image_004.jpg");
    animation-delay: 21s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(2) {
    background-image: url("/images/bg/image_003.jpg");
    animation-delay: 14s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(3) {
    background-image: url("/images/bg/image_002.jpg");
    animation-delay: 7s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(4) {
    background-image: url("/images/bg/image_001.jpg");
    animation-delay: 0;
    pointer-events: none;
  }
}

@include on-small-screen(){
  .crossfade div:nth-of-type(1) {
    background-image: url("/images/vhs_2023/image_014.jpg");
    animation-delay: 21s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(2) {
    background-image: url("/images/paris_flou/image_016.jpg");
    animation-delay: 14s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(3) {
    background-image: url("/images/bg/image_003_vert.jpg");
    animation-delay: 7s;
    pointer-events: none;
  }
  .crossfade div:nth-of-type(4) {
    background-image: url("/images/bg/image_001_vert.jpg");
    animation-delay: 0;
    pointer-events: none;
  }
}
